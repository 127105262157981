import { LayoutService } from '@/services/layout-service'
import { Component, Vue } from 'vue-property-decorator'
import Commons from '@/components/shared/Helpers/commons'
import { IDateRange } from "@/models/common-models"
import { ValidationObserver } from 'vee-validate'
import Alert from '@/components/shared/Alert.vue'
import { AuthService } from '@/services/auth-service'
import { AdministrationHelper } from '../AdministrationHelper'

@Component({
  components: {
    ValidationObserver,
    Alert
  }
})

export default class Statistics extends Vue {
  private layoutService = LayoutService.getInstance()
  private authService = AuthService.getInstance()

  public tab = null
  public tabs = ['Patients', 'Consultations']
  public apiURL = process.env.VUE_APP_API_URL

  public isLoading = false
  public errorMessages: string[] = []

  public searchPeriodPatient: IDateRange = {
    from: `${new Date().getFullYear()}-01`,
    to: new Date().toISOString().substr(0, 7)
  }

  public searchPeriodConsultation: IDateRange = {
    from: `${new Date().getFullYear()}-01`,
    to: new Date().toISOString().substr(0, 7)
  }

  public mounted () {
    this.layoutService.updateDrawerList(AdministrationHelper.GetAdminNavItems())
    if (!this.canGetStatistics()) {
      this.errorMessages.push('Vous ne disposez pas de droits suffisants pour effectuer cette opération')
    }
  }

  public get searchPeriodPatientFrom () {
    return this.searchPeriodPatient?.from ? new Date(this.searchPeriodPatient?.from.trim()) : new Date()
  }

  public get searchPeriodPatientTo () {
    return this.searchPeriodPatient?.to ? new Date(this.searchPeriodPatient?.to.trim()) : new Date()
  }

  public get searchPeriodConsultationFrom () {
    return this.searchPeriodConsultation?.from ? new Date(this.searchPeriodConsultation?.from.trim()) : new Date()
  }

  public get searchPeriodConsultationTo () {
    return this.searchPeriodConsultation?.to ? new Date(this.searchPeriodConsultation?.to.trim()) : new Date()
  }

  public get exportErrorHandler () {
    return (e) => {
      Commons.defaultVAuthHrefErrorHandler(this.errorMessages, e)
    }
  }

  public canGetStatistics () {
    return this.authService.Roles.includes('Statistics') || this.authService.Roles.includes('Statistics_ReadOnly')
  }

  public hideAlert () {
    this.errorMessages = []
  }
}
